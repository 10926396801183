<template>
  <v-container fluid class="mt-5 mb-2">
    <alert-notification :message="alert" />
    <div style="max-width:500px;" class="ma-auto">
      <v-autocomplete v-model="areaId" :items="areas" item-value="id" item-text="name" :loading="loading" :disabled="loading" :label="$t('admin.sub.distribution.city')"
                      dense chips filled rounded solo clearable style="text-transform:uppercase;" @change="refreshZones"
      />
    </div>
    <div style="max-width:1000px;" class="ma-auto">
      <v-card class="ma-0">
        <v-card-title>
          {{ $t('admin.sub.distribution.subtitle') }}
          <v-spacer />
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('admin.sub.distribution.table.search')" single-line hide-details />
        </v-card-title>
        <v-data-table :headers="headers" :options="options" :items="zones" :loading="loading" :search="search"
                      :hide-default-header="false" :hide-default-footer="true"
        >
          <template #[`item.direction`]="{ item }">
            <v-btn color="secondary" class="white--text" small :href="gpsLink('google_maps', item)" target="_blank">
              Google
              <v-icon right dark>
                mdi-google-maps
              </v-icon>
            </v-btn>
          </template>
          <template #[`item.accessType`]="{ item }">
            <v-select v-model="item.accessType" :items="accessTypes" item-value="id" item-text="name" dense solo hide-details style="margin:0 auto;max-width:120px" :disabled="true" />
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn small rounded color="primary" :loading="loading" @click.stop="openZoneEditor(item)">
              <v-icon medium dark>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-dialog v-model="openEditDialog" max-width="500" :fullscreen="true">
      <v-card>
        <v-toolbar dense color="primary">
          <v-btn icon dark @click.stop="openEditDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="validForm" lazy-validation @keyup.native.enter="validate() && saveCurrentZone()">
            <v-row>
              <v-col cols="12" sm="3" md="3" />
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="currentZoneName" :counter="currentZoneNameMaxLength" :rules="currentZoneNameRules"
                              required :label="$t('admin.sub.distribution.input.name.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="3" md="3" />
              <v-col cols="12" sm="3" md="3" />
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="currentZoneDistrict" :counter="currentZoneDistrictMaxLength" :rules="currentZoneDistrictRules"
                              required :label="$t('admin.sub.distribution.input.district.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="3" md="3" />
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="close" @click.stop="openEditDialog=false">
            {{ $t('admin.sub.distribution.button.close') }}
          </v-btn>
          <v-btn :disabled="!validForm || loading" :loading="loading" color="success" @click.stop="validate() && saveCurrentZone()">
            {{ $t('admin.sub.distribution.button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { Logger } from '@/services/common/Logger';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import DistributionAccessType from '@/services/distribution/DistributionAccessType';

  export default {
    name: 'AdminDistributionZone',
    components: { AlertNotification },
    data () {
      return {
        alert: '',
        loading: false,
        search: '',
        areaId: undefined,
        areas: [],
        zones: [],
        headers: [
          {
            text: this.$i18n.t('admin.sub.distribution.table.direction'),
            align: 'start',
            filterable: false,
            sortable: false,
            value: 'direction'
          },
          {
            text: this.$i18n.t('admin.sub.distribution.table.district'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'district'
          },
          {
            text: this.$i18n.t('admin.sub.distribution.table.name'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('admin.sub.distribution.table.accessType'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'accessType'
          },
          {
            text: this.$i18n.t('admin.sub.distribution.table.action'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'action'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        openEditDialog: false,
        validForm: true,
        currentZone: undefined,
        currentZoneName: null,
        currentZoneNameMinLength: 1,
        currentZoneNameMaxLength: 100,
        currentZoneNameRules: [
          v => !!v || this.$t('admin.sub.distribution.input.name.rule.required'),
          v => (v && v.length >= this.currentZoneNameMinLength) || this.$t('admin.sub.distribution.input.name.rule.minLength', { count: this.currentZoneNameMinLength }),
          v => (v && v.length <= this.currentZoneNameMaxLength) || this.$t('admin.sub.distribution.input.name.rule.maxLength', { count: this.currentZoneNameMaxLength })
        ],
        currentZoneDistrict: null,
        currentZoneDistrictMinLength: 1,
        currentZoneDistrictMaxLength: 100,
        currentZoneDistrictRules: [
          v => !!v || this.$t('admin.sub.distribution.input.district.rule.required'),
          v => (v && v.length >= this.currentZoneDistrictMinLength) || this.$t('admin.sub.distribution.input.district.rule.minLength', { count: this.currentZoneDistrictMinLength }),
          v => (v && v.length <= this.currentZoneDistrictMaxLength) || this.$t('admin.sub.distribution.input.district.rule.maxLength', { count: this.currentZoneDistrictMaxLength })
        ],
        accessTypes: [
          { id: DistributionAccessType.unknown, name: 'Inconnu' },
          { id: DistributionAccessType.street, name: 'sur rue' },
          { id: DistributionAccessType.vigik, name: 'Vigik' }
        ]
      };
    },
    mounted: async function () {
      await this.refreshAreas();
    },
    methods: {
      gpsLink: function (appName, zone) {
        const addresses = [zone.name];
        const area = this.getCurrentArea();
        const city = (area === undefined ? '' : ' - ' + area.name + ' - ' + area.code);
        return 'https://maps.google.com/?daddr=' + encodeURIComponent(addresses.join(' & ') + city + ' - France');
      },
      getCurrentArea: function () {
        let area;
        let i = 0;
        while (area === undefined && i < this.areas.length) {
          if (this.areas[i].id === this.areaId) {
            area = this.areas[i];
          }
          i++;
        }

        return area;
      },
      openZoneEditor (item) {
        this.currentZone = item;
        this.currentZoneName = this.currentZone.name;
        this.currentZoneDistrict = this.currentZone.district;
        this.openEditDialog = true;
      },
      async saveCurrentZone () {
        if (this.currentZone === undefined) {
          Logger.exception(new Error('update name and district: current zone is undefined'));
          return;
        }

        this.loading = true;
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'distribution/zone/name',
                               {
                                 zoneId: this.currentZone.id,
                                 name: (this.currentZoneName === null ? '' : this.currentZoneName),
                                 district: (this.currentZoneDistrict === null ? '' : this.currentZoneDistrict)
                               });
          this.success();
          this.openEditDialog = false;
        } catch (error) {
          await this.fail(error, this.$i18n.t('distribution.error'));
        }
        this.loading = false;
        await this.refreshZones();
      },
      validate () {
        return this.$refs.form.validate();
      },
      async refreshAreas () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'distribution/areas/all');
          if (response.data !== undefined && response.data.areas !== undefined) {
            this.areas = response.data.areas;
          }
          this.success();
        } catch (error) {
          await this.fail(error, this.$i18n.t('distribution.error'));
        }
        this.loading = false;
      },
      async refreshZones () {
        if (this.areaId === undefined || this.areaId === null) {
          this.zones = [];
          return;
        }

        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'distribution/zones/all?areaId=' + this.areaId);
          if (response.data !== undefined && response.data.zones !== undefined) {
            this.zones = response.data.zones;
          }
          this.success();
        } catch (error) {
          await this.fail(error, this.$i18n.t('distribution.error'));
        }
        this.loading = false;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
